
import { AllLabels, CaseData } from '@/interfaces';
import { labelsStore, uploadStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import SelectCasePageTable from './SelectCasePageTable.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { BUTTON_BACKGROUND_COLOR, BUTTON_TEXT_COLOR } from '@/utils/Constants';
import { exportService } from '@/services';

@Component({
  components: {
    SelectCasePageTable,
    AppButton
  }
})
export default class ProcessingPageContent extends Vue {

  public get selectedCase(): CaseData | null {
    return uploadStore.selectedCase;
  }

  public get cases(): CaseData[] {
    return uploadStore.cases;
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get canContinue(): boolean {
    return !!this.selectedCase;
  }

  public onAddMoreFilesClicked(): void {
    uploadStore.showUploadPage();
  }

  public onCaseSelected(caseData: CaseData): void {
    uploadStore.selectCase(caseData);
  }

  public onContinueClicked(): void {
    uploadStore.showResultsReadyPage();
  }

  public async onExportClicked(): Promise<void> {
    const cases = this.cases;
    for (let i = 0; i < cases.length; i++) {
      await exportService.exportThermogardCsv(cases[i]);
    }
  }

  public get cssVars(): {[key: string]: string} {
    const result = {
      '--app-button-color': BUTTON_BACKGROUND_COLOR,
      '--app-button-text-color': BUTTON_TEXT_COLOR
    };
    return result;
  }
}

