import { errorStore } from '@/store';
import VueRouter, { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/pages/HomePage/HomePage.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/pages/HomePage/HomePage.vue')
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import('@/pages/DemoPage/DemoPage.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/pages/ContactPage/ContactPage.vue')
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/error/:statusCode',
    name: 'Error',
    component: () => import('@/pages/ErrorPage/ErrorPage.vue'),
    props: true
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach(() => {
  errorStore.clearErrorPage();
  return true;
});

export default router;