import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';
import { LabelsApi } from '@/api';
import { AllLabels } from '@/interfaces';
import { browserLocale, languagePreference } from '@/utils';

const defaultLabels: AllLabels = {
  common: {
    text: {
      somethingWentWrong: 'Something went wrong. Please try again.!',
    }
  },
  productNavigation: {
    productName: 'myTTM',
    contactTab: 'Contact',
    homeTab: 'Home',
    demoTab: 'Demo',
  },
  temp: {
    productTitle1: 'my',
    productTitle2: 'TTM',
    productSubTitle: 'Data Insights',
    footerText: 'Powered by',
    demoOfferingStartText: 'First time?',
    demoOfferingLinkText: 'Click here',
    demoOfferingEndText: 'to view a demo case',
    processingTitle: 'Processing...',
    processingColumnFile: 'File',
    processingColumnStatus: 'Status',
    processingStatusComplete: 'Complete',
    processingStatusProcessing: 'Processing...',
    processingStatusNotStated: 'Queued',
    processingRetryButton: 'Retry',
    selectCaseTitle: 'Select a case',
    selectCaseDescription: 'Data was uploaded for mutiple cases.\nPlease select which case you would like to view results.',
    addMoreFilesButton: 'Add more files',
    exportThermogardCasesButton: 'Export Thermogard Cases',
    selectCaseColumnDateTime: 'Case Started',
    selectCaseColumnDuration: 'Total Case Duration',
    selectCaseColumnFiles: 'File(s)',
    resultsTabsCaseInsights: 'Case Summary',
    resultsTabsKeyMetrics: 'Key Metrics',
    resultsTabsTimeline: 'Timeline',
    resultsTabsGraph: 'Graph',
    keyMetricsTimeWithinTargetRange: 'Time within target range',
    keyMetricsTimeWithinTargetRangeTooltip: 'Time within target range only encompasses the \'Maintenance\' Phase',
    keyMetricsCoolingRate: 'Cooling Rate',
    keyMetricsWarmingRate: 'Warming Rate',
    keyMetricsFeverBurden: 'Fever Burden',
    keyMetricsFeverBurdenTooltip: 'Percent of time patient temperature is over 38°C',
    keyMetricsTimeToTarget: 'Time to Target',
    casesPlotTooltipHeader: 'Show data:',
    casesPlotTooltipTargetTemperature: 'Target Temp',
    casesPlotTooltipPatientTemperature: 'Patient Temp',
    casesPlotTooltipMachinePower: 'Machine Power',
    casesPlotTooltipBathTemperature: 'Coolant Temp',
    casesPlotTooltipMachineStatus: 'Machine Status',
    casesPlotTooltipAlarm: 'Alarm',
    casesPlotTooltipNotes: 'Notes',
    casesPlotTooltipNotesTooltip: 'Double click on the graph to create a note',
    casesPlotTooltipFeverMode: 'FEVER mode',
    casesPlotTooltipMaxMode: 'MAX mode',
    casesPlotTooltipUnknownMode: 'UNKNOWN mode',
    casesPlotTooltipWarmingMode: 'WARMING mode',
    casesPlotTooltipControlModeUnits: '°C/hr',
    casesPlotMultiColorPatientTemperatureSwitch: 'Patient Temp Colors',
    casesPlotSinglePhaseSwitch: 'Single Phase',
    casesPlotSingleTemperatureAxisSwitch: 'Single Temperature Axis',
    casesPlotSinglePhaseName: 'Time',
    casesPlotAxisBathTemperature: 'Coolant',
    casesPlotAxisMachinePower: 'Power',
    casesPlotAxisPatientTemperature: 'Patient',
    enum: {
      casePlotPhase: {
        Induction: 'Induction',
        Maintenance: 'Maintenance',
        Normothermia: 'Normothermia',
        Rewarming: 'Rewarming',
        Unknown: 'Unknown',
      },
      machineState: {
        off: 'Off',
        run: 'Run',
        standby: 'Standby'
      }
    },
    commonAlertAlert: 'Alert',
    commonAlarmAlarm: 'Alarm',
    casesTimelineCaseStarted: 'Case Started',
    casesTimelinePhaseStarted: 'Phase Started',
    casesPlotLegendBathTemperature: 'Coolant Temp',
    casesPlotLegendMachineCoolingPower: '% Cooling Power',
    casesPlotLegendMachineWarmingPower: '% Warming Power',
    casesPlotLegendPatientTemperature: 'Patient Temp',
    casesPlotLegendTargetTemperature: 'Target Temp',
    casesDialogCsvExportNotSupported: 'CSV Export is not supported for system type:',
    commonButtonsOk: 'OK',
    commonButtonsCancel: 'Cancel',
    casesButtonsExportCsv: 'Export CSV',
    casesButtonsExportPng: 'Export PNG',
    casesTextDuration: 'Case Duration',
    casesFirstTargetCooling: 'Cooling',
    casesFirstTargetLabel: 'first target',
    casesFirstTargetNormothermiaLine1: 'Normo',
    casesFirstTargetNormothermiaLine2: 'thermia',
    casesFirstTargetWarming: 'Warming',
    casesSystemInfoDeviceSerialNumber: 'System Serial Number',
    casesSystemInfoDeviceType: 'System Type',
    casesSystemInfoPumpHours: 'Pump Hours at Power On',
    casesSystemInfoCaseDateTime: 'Case Start Time',
    casesSystemInfoHospitalArrivalToStartCooling: 'Arrival to Case Start',
    casesSystemInfoPadType: 'Pad Type',
    casesSystemInfoHeadPad: 'Head',
    casesSystemInfoTorsoPad: 'Torso',
    casesSystemInfoThighPad: 'Thigh',
    questionnaireCaseTypeLabel: 'Case Type',
    questionnairePeripheralTypeLabel: 'Device Type',
    questionnairePatientWeightLabel: 'Patient Weight',
    questionnairePatientOutcomeLabel: 'Patient Outcome',
    questionnaireHospitalArrivalTimeLabel: 'Hospital Arrival (optional)',
    errorHospitalArrivalTimeAfterCaseStart: 'Hospital Arrival must be before the start of the case',
    casesTimelineHospitalArrival: 'Hospital Arrival',
  }
};

@Module({ name: 'LabelsModule', namespaced: true })
export default class LabelsModule extends VuexModule {
  public labels: AllLabels = defaultLabels;
  public language: string = 'en';
  public hasLabels: boolean = false;

  @MutationAction({ mutate: ['labels', 'language'] })
  async fetchLabels() {
    let labels: AllLabels;
    let language: string = this.language;
    if (this.hasLabels) {
      // already have data, don't fetch again
      labels = this.labels;
    } else {
      const tempLabels = this.labels.temp;
      labels = await LabelsApi.getLabels();
      labels.temp = tempLabels;
      language = languagePreference(browserLocale());
    }
    return {
      labels: labels,
      language: language
    };
  }
}