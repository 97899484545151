import saveAs from 'file-saver';
import { eventService, ToastErrorEvent } from './EventService';
import { CaseData, PlotDataPoint } from '@/interfaces';
import { settingsStore } from '@/store';
import { isThermogardDevice } from '@/utils';

export class ExportService {
  public async exportThermogardCsv(caseData: CaseData): Promise<void> {
    if (!isThermogardDevice(caseData)) {
        return;
    }
    let fileName = caseData.files[0];
    if (fileName.endsWith('.tg2')) {
        fileName = fileName.split('.').slice(0, -1).join('.') + '.csv';
    }
    await this.export(fileName, async () => {
      const fileString = this.createCsvFileData(caseData);
      return new Blob([fileString]);
    });
  }

  public async export(fileName: string, getDataMethod: () => Promise<Blob>) {
    try {
      const content = await getDataMethod();
      if (window.saveAs) {
        window.saveAs(content, fileName);
      } else {
        saveAs(content, fileName);
      }
    } catch (e) {
      eventService.publish(new ToastErrorEvent());
      throw e;
    }
  }

  public createCsvFileData(caseData: CaseData): string {
    // unpack some values
    const graphData: PlotDataPoint[] = caseData.graphData;
    const startTime = caseData.caseDateTime;

    const labels: string[] = [
      'Date/Time',
      'Patient Temp',
      'Target Temp',
      'Bath Temp',
      'State'
    ];
    const rows: string[] = [
      this._createCsvRow(labels)
    ];
    graphData.forEach((graphDataPoint) => {
      const rowData: string[] = [
        startTime.plusMilliseconds(graphDataPoint.timeMs).toDisplayDateTime(settingsStore.use12HourClock),
        graphDataPoint.patientTemp.toString(),
        graphDataPoint.targetTemp.toString(),
        graphDataPoint.bathTemp.toString(),
        graphDataPoint.state
      ];
      rows.push(this._createCsvRow(rowData));
    });
    const fileString = rows.join('\n');
    return fileString;
  }

  private _createCsvRow(columns: string[]): string {
    return columns
      .map((column) => column.replace('"', '""')) // escape quotes
      .map((column) => `"${column}"`) // wrap values in quotes
      .join(','); // separate with commas
  }
}

export const exportService = new ExportService();