
import { AllLabels, QuestionnaireResult } from '@/interfaces';
import { labelsStore, questionnaireStore, settingsStore, uploadStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import AppButton from '@/components/ui/AppButton.vue';
import TextInputWrapper from '@/components/ui/TextInputWrapper.vue';
import { CaseApi } from '@/api';
import { DateWrapper, isBrainCoolDevice } from '@/utils';
import DateTimeInputWrapper from '@/components/ui/DateTimeInputWrapper.vue';
import { zollServiceService } from '@/services';

const LBS_OPTION = 'lbs';
const KG_OPTION = 'kg';
const CPC_OPTION = 'CPC';
const MRS_OPTION = 'mRS';
const CUSTOM_CASE_TYPE_OPTION = 'Other';

@Component({
  components: {
    AppButton,
    TextInputWrapper,
    DateTimeInputWrapper
  }
})
export default class ResultsReadyPageContent extends Vue {
  
  public caseTypeOptions: string[] = [
    'Cardiac',
    'Neurogenic Fever',
    'Severe Burn',
    'Heat Stroke',
    'Environmental Hypothermia',
    'Trauma',
    'Surgery (Normothermia)',
    'Sepsis',
    CUSTOM_CASE_TYPE_OPTION,
  ];
  public peripheralTypeOptions: string[] = [
    'Cool Line (2 balloon, neck catheter)',
    'Solex 7 (coil, neck catheter)',
    'Icy (3 balloon, femoral catheter)',
    'Quattro (4 balloon, femoral catheter)',
    'STx Vest (Surface Start-Up Kit)',
    'STx Thigh Pad (Surface Start-Up Kit)',
    'Other',
    'Unknown'
  ];
  public lbsWeightOptions: string[] = [
    '<= 99 lbs',
    '100-119 lbs',
    '120-139 lbs',
    '140-159 lbs',
    '160-179 lbs',
    '180-199 lbs',
    '200-229 lbs',
    '230-259 lbs',
    '260-299 lbs',
    '300-339 lbs',
    '>= 340 lbs',
    'Unknown'
  ];
  public kgWeightOptions: string[] = [
    '<= 49 kg',
    '50-59 kg',
    '60-69 kg',
    '70-79 kg',
    '80-89 kg',
    '90-99 kg',
    '100-119 kg',
    '120-139 kg',
    '140-159 kg',
    '160-179 kg',
    '>= 180 kg',
    'Unknown'
  ];
  public massUnitOptions: string[] = [LBS_OPTION, KG_OPTION];
  public mRSOutcomeOptions: string[] = [
    '0 (No Symptoms)',
    '1 (No Significant Disability)',
    '2 (Slight Disability)',
    '3 (Moderate Disability)',
    '4 (Moderately Severe Disability)',
    '5 (Severe Disability)',
    '6 (Dead)',
    'Unknown'
  ];
  public CPCOutcomeOptions: string[] = [
    '1 (Good Cerebral Performance)',
    '2 (Moderate Cerebral Disability)',
    '3 (Severe Cerebral Disability)',
    '4 (Coma/Vegetative State)',
    '5 (Brain Death)',
    'Unknown'
  ];
  public outcomeUnitOptions: string[] = [MRS_OPTION, CPC_OPTION];

  public caseTypeSelection: string = '';
  public peripheralTypeSelection: string = '';
  public weightSelection: string = '';
  public massUnit: string = LBS_OPTION;
  public outcomeSelection: string = '';
  public outcomeUnit: string = MRS_OPTION;
  public customCaseType: string = '';
  public hospitalArrivalTime: DateWrapper | null = null;
  public hospitalArrivalTimeErrorMessage: string = '';

  public mounted(): void {
    const questionnaireResult = questionnaireStore.getQuestionnaireResult(uploadStore.selectedCase!);
    const isServicePage = zollServiceService.isServicePage(this.$router);
    if (questionnaireResult || isServicePage) {
      // already answered the questionnaire for this case, go to the results
      uploadStore.showResultsPage();
    }
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get showCustomCaseType(): boolean {
    return this.caseTypeSelection === CUSTOM_CASE_TYPE_OPTION;
  }

  public get showPeripheralType(): boolean {
    return !this.isBrainCool;
  }

  public get isBrainCool(): boolean {
    return isBrainCoolDevice(uploadStore.selectedCase!);
  }

  public get hospitalArrivalTimeString(): string {
    return this._dateToString(this.hospitalArrivalTime);
  }

  public get weightOptions(): string[] {
    return this.massUnit == LBS_OPTION ? this.lbsWeightOptions : this.kgWeightOptions;
  }

  public setMassUnit(massUnit: string): void {
    this.massUnit = massUnit;
    this.weightSelection = '';
  }

  public setOutcomeUnit(outcomeUnit: string): void {
    this.outcomeUnit = outcomeUnit;
    this.outcomeSelection = '';
  }

  public get outcomeOptions(): string[] {
    return this.outcomeUnit == MRS_OPTION ? this.mRSOutcomeOptions : this.CPCOutcomeOptions;
  }

  public async onViewResultsClicked(): Promise<void> {
    const result: QuestionnaireResult = {
      caseType: this.caseTypeSelection,
      peripheralType: this.isBrainCool ? '' : this.peripheralTypeSelection,
      patientWeight: this.weightSelection,
      patientMassUnit: this.massUnit,
      patientOutcome: this.outcomeSelection,
      patientOutcomeUnit: this.outcomeUnit,
      customCaseType: this.showCustomCaseType ? this.customCaseType : '',
      hospitalArrivalTime: this.hospitalArrivalTimeString,
      documentKeys: uploadStore.selectedCase!.documentKeys
    };
    await CaseApi.submitQuestionnaire(result);
    questionnaireStore.setQuestionnaireResult({
      caseData: uploadStore.selectedCase!,
      result: result
    });
    console.log(uploadStore.selectedCase!);
    console.log(result);
    uploadStore.showResultsPage();
  }

  public get canViewResults(): boolean {
    const validCaseTypeSelection = this.caseTypeSelection && (!this.showCustomCaseType || this.customCaseType);
    const validPeripheralTypeSelection = this.peripheralTypeSelection || this.isBrainCool;
    const hospitalArrivalTimeValid = !this.hospitalArrivalTimeErrorMessage;
    return !!(validCaseTypeSelection && validPeripheralTypeSelection && this.weightSelection && this.outcomeSelection && hospitalArrivalTimeValid);
  }

  public setHospitalArrivalTime(value: string): void {
    this.hospitalArrivalTime = new DateWrapper(value);
  }

  public setHospitalArrivalTimeErrorMessage(value: string): void {
    this.hospitalArrivalTimeErrorMessage = value;
  }

  public get hospitalArrivalTimeError(): string {
    if (!this.hospitalArrivalTime || this.hospitalArrivalTime.isNaN()) {
      // invalid hospital arrival time, there is already an error, don't add another
      return '';
    }
    if (this.hospitalArrivalTime.isAfter(uploadStore.selectedCase!.caseDateTime)) {
      // hospital arrival time is after the start of the case, that can't be right
      return this.labels.temp.errorHospitalArrivalTimeAfterCaseStart + ' (' + this._dateToString(uploadStore.selectedCase!.caseDateTime) + ')';
    }
    return '';
  }

  public get selectRules(): any[] {
    return [
      (text: string) => {
        if (!text || !text.trim()) {
          return 'Required';
        } else {
          return true;
        }
      }
    ];
  }

  private _dateToString(date: DateWrapper | null): string {
    if (!date || date.isNaN()) {
      return '';
    }

    return date.toDisplayDateTime(settingsStore.use12HourClock);
  }
}

